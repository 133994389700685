<template>
  <f7-page class="profile-page">
    <template #fixed>
      <ProfileNavigationComponent type="back" :title="$t.getTranslation('LBL_MERGE_ACCOUNT')" :settings="false" />
    </template>

    <section class="merge-account-container">
      <div class="container">
        <div class="content">
          <p>{{ $t.getTranslation("LBL_ACCOUNT_MERGE_SUB") }}</p>
          <p class="warning">
            <strong>{{ $t.getTranslation("LBL_WARNING") }}</strong> {{ $t.getTranslation("LBL_ACCOUNT_MERGE_WARNING") }}
          </p>
        </div>

        <div class="account">
          <f7-list no-hairlines no-hairlines-between>
            <f7-list-item divider>{{ $t.getTranslation("LBL_MERGE_INFORMATION") }}</f7-list-item>
            <f7-list-input
              v-model:value="formData.MobileCode"
              name="MobileCode"
              :required="validationRules?.MobileCode?.required"
              :minlength="validationRules?.MobileCode?.minimumLength"
              :maxlength="validationRules?.MobileCode?.maximumLength"
              :label="$t.getTranslation('LBL_MOBILE_CODE')"
              :placeholder="$t.getTranslation('LBL_MOBILE_CODE_PLACEHOLDER')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <template v-if="mobileCodeList && mobileCodeList.length > 0">
                <option v-for="mobileCode in mobileCodeList" :key="'mc_' + mobileCode.value" :value="mobileCode.value">{{ mobileCode.name }}</option>
              </template>
            </f7-list-input>
            <f7-list-input
              v-model:value="formData.MobileNumber"
              name="MobileNumber"
              :required="validationRules?.MobileNumber?.required"
              :minlength="validationRules?.MobileNumber?.minimumLength"
              :maxlength="validationRules?.MobileNumber?.maximumLength"
              :label="$t.getTranslation('LBL_MOBILE_NUMBER')"
              :placeholder="$t.getTranslation('LBL_MOBILE_NUMBER_PLACEHOLDER')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="tel"
              validate
              clear-button
            >
            </f7-list-input>
            <f7-list-item>
              <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing" @click="verify">{{ $t.getTranslation("LBL_VERIFY") }}</f7-button>
            </f7-list-item>

            <f7-list-input
              v-model:value="formData.OTP"
              name="OTP"
              :required="validationRules?.OTP?.required"
              :minlength="validationRules?.OTP?.minimumLength"
              :maxlength="validationRules?.OTP?.maximumLength"
              :label="$t.getTranslation('LBL_OTP')"
              :placeholder="$t.getTranslation('LBL_OTP_PLACEHOLDER')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="tel"
              validate
              clear-button
            >
            </f7-list-input>

            <f7-list-item divider>{{ $t.getTranslation("LBL_CURRENT_ACCOUNT_INFORMATION") }}</f7-list-item>
            <f7-list-input
              v-model:value="formData.UserName"
              name="UserName"
              :required="validationRules?.UserName?.required"
              :minlength="validationRules?.UserName?.minimumLength"
              :maxlength="validationRules?.UserName?.maximumLength"
              :label="$t.getTranslation('LBL_USERNAME')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
              clear-button
            >
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.Password"
              name="Password"
              :required="validationRules?.Password?.required"
              :minlength="validationRules?.Password?.minimumLength"
              :maxlength="validationRules?.Password?.maximumLength"
              :label="$t.getTranslation('LBL_PASSWORD')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              :type="isPasswordShown ? 'text' : 'password'"
              placeholder="********"
              validate
            >
              <template #content-end>
                <f7-link tabindex="-1" class="show-password" @click="togglePassword">
                  <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
                </f7-link>
              </template>
            </f7-list-input>

            <f7-list-item>
              <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="next">{{ $t.getTranslation("LBL_MERGE_ACCOUNT") }}</f7-button>
            </f7-list-item>
          </f7-list>
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, inject, computed, onMounted, defineAsyncComponent } from "vue";

import { post } from "@/utils/axios";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));


export default defineComponent({
  name: "SettingsMergePage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();
   
    store.dispatch("config/fetchData", { params: { mobileCodeList: 1 } });
    const mobileCodeList = computed(() => store.getters["config/getData"]?.mobileCodeList);
    const userInfo = computed(() => store.getters["user/getData"]);

    const userMergeInfo = ref(false);
    const isPasswordShown = ref(false);
    const isButtonProcessing = ref(false);

    const formData = reactive({
      UserName: "",
      Password: "",
      MobileCode: "",
      MobileNumber: "",
      OTP: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const togglePassword = () => {
      if (isPasswordShown.value) {
        hidePassword();
      } else {
        showPassword();
      }
    };

    const showPassword = (event) => {
      isPasswordShown.value = true;
    };

    const hidePassword = (event) => {
      isPasswordShown.value = false;
    };

    const verify = async () => {
      if (formData.MobileCode != "" && formData.MobileNumber != "") {
        isButtonProcessing.value = true;
        let returnData = await post("/user/merge/otp/generate", formData);

        if (returnData?.isOTPGenerated) {
          helpers.createNotification({
            type: "info",
            title: $t.getTranslation("LBL_INFO"),
            message: $t.getTranslation("LBL_INFO_SUCCESS_OTP_GENERATE"),
          });
        }

        isButtonProcessing.value = false;
      }
    };
    const next = async () => {
      let isValid = validate(true);

      if (isValid) {
        isButtonProcessing.value = true;
        let returnData = await post("/user/merge", formData);
        userMergeInfo.value = returnData;

        if (returnData?.isMergeSuccess) {
          helpers.createNotification({
            type: "info",
            title: $t.getTranslation("LBL_INFO"),
            message: $t.getTranslation("LBL_INFO_SUCCESS_ACCOUNT_MERGE"),
          });

          props.f7router.back({ force: true });
        }

        isButtonProcessing.value = false;
      }
    };

    onMounted(() => {
      formData.MobileCode = userInfo?.value?.MobileCode;
      formData.MobileNumber = userInfo?.value?.MobileNumber;
      formData.UserName = userInfo?.value?.UserName;
    });

    return {
      mobileCodeList,
      userInfo,
      userMergeInfo,
      formData,
      validationRules,
      isButtonProcessing,
      isDisableNextButton,
      isPasswordShown,
      showPassword,
      hidePassword,
      togglePassword,
      next,
      verify,
    };
  },
});
</script>
